import InfoCountMixin from '~/mixins/InfoCountMixin'
import InfoCounter from '@theme/components/atom/InfoCounter'

export default {
  mixins: [InfoCountMixin],
  components: {
    InfoCounter,
  },
  data() {
    return {
      cartPriceVisible: true,
      compareInfoComponent: null,
      wishListInfoComponent: null,
      wishListForceDisable: false,
    }
  },
  mounted() {
    if (this.$contentSettings.functions.compare) {
      import('@theme/components/product/CompareInfo').then(component => {
        this.compareInfoComponent = component.default
      })
    }
    if (!this.wishListForceDisable && this.$contentSettings.functions.wishList) {
      import('@theme/components/user/WishListInfo').then(component => {
        this.wishListInfoComponent = component.default
      })
    }
  },
}
