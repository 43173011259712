var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Container',{staticClass:"HeaderMain",class:{'HeaderMain--stickyOnMobile': _vm.$themeSettings.components.HeaderMain.enableStickyOnMobile},attrs:{"full-viewport-width-wrapper":_vm.$themeSettings.components.HeaderMain.fullViewportWidthWrapper}},[_c('div',{staticClass:"HeaderMain__rowWrapper"},[_c('div',{staticClass:"row flex-nowrap align-items-stretch justify-content-between print-justify-content-between"},[_c('div',{staticClass:"col-8 d-md-flex col-md-6 align-items-md-center",attrs:{"role":"banner"}},[_c('nuxt-link',{attrs:{"to":_vm.localePath({name: 'index'}),"title":_vm.$t('HeaderMain.logoTitle', { website: this.$t('globals.website') })}},[_c('div',{staticClass:"row no-gutters align-items-stretch"},[_c('Logo',{ref:"logo"}),_c('HeaderMainLogoHook')],1)])],1),_c('div',{staticClass:"d-flex col-16 d-md-none align-items-center justify-content-end d-print-none"},[_c('div',{staticClass:"HeaderMain__iconMobile"},[_c('a',{attrs:{"href":_vm.localePath({name: 'search'})},on:{"click":_vm.toggleSearch}},[_c('svgicon',{attrs:{"icon":require('@icon/search.svg?raw'),"fill":""}})],1)]),_c('div',{staticClass:"HeaderMain__iconMobile"},[_c('nuxt-link',{attrs:{"to":_vm.localePath({name: _vm.userLink})}},[_c('svgicon',{attrs:{"icon":require('@icon/person.svg?raw'),"fill":""}})],1)],1),_c('div',{staticClass:"HeaderMain__iconMobile HeaderMain__iconMobile--cartInfo"},[_c('CartInfo')],1),_c('div',{staticClass:"HeaderMain__hamburgerWrapper",class:{
            'HeaderMain__hamburgerWrapper--compact':
              _vm.$contentSettings.functions.compare
              || _vm.$contentSettings.functions.wishList
          }},[_c('button',{staticClass:"hamburger hamburger--squeeze",class:{'is-active': _vm.open},attrs:{"id":"MainMenuMobileHamburger","aria-label":[_vm.open ? _vm.$t('HeaderMain.mobileMenuButton.ariaLabelClose') : _vm.$t('HeaderMain.mobileMenuButton.ariaLabelOpen')],"aria-expanded":[_vm.open ? 'true' : 'false'],"aria-controls":_vm.mainMenuMobileAriaControls,"type":"button"},on:{"click":_vm.toggleMobileMenu}},[_c('span',{staticClass:"hamburger-box"},[_c('span',{staticClass:"hamburger-inner"})]),_c('small',{staticClass:"HeaderMain__hamburgerButtonText"},[_vm._v(" "+_vm._s(_vm.$t('HeaderMain.mobileMenuButton.text'))+" ")])])])]),_c('div',{staticClass:"d-none d-md-flex flex-wrap justify-content-center align-items-sm-center d-print-none",class:{
          'offset-md-1 offset-xl-0':
            !(_vm.$contentSettings.functions.compare
              || _vm.$contentSettings.functions.wishList),
              [_vm.$themeSettings.components.HeaderMain.searchSizeClass]: true
        }},[(_vm.$themeSettings.components.HeaderMain.showContactInfo)?_c('div',{staticClass:"HeaderMain__contact-info"},[_c('span',[_c('svgicon',{staticClass:"svg-icon-offset-right",attrs:{"icon":_vm.phoneIcon,"fill":""}}),(_vm.phonePrepend)?_c('span',{staticClass:"HeaderMain__phone-prepend mr-2"},[_vm._v(_vm._s(_vm.phonePrepend))]):_vm._e(),_c('a',{staticClass:"HeaderMain__phone mr-2 text-body",attrs:{"href":`tel:${_vm.phoneHref}`,"aria-label":_vm.accessiblePhoneNumber}},[_c('strong',{attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm.phoneText))])]),_c('span',{staticClass:"HeaderMain__openingHours"},[_vm._v(" "+_vm._s(_vm.openingHours)+" ")])],1)]):_vm._e(),_c('SearchInput',{attrs:{"autofocus":_vm.searchInputFocus}})],1),(_vm.$themeSettings.components.HeaderMain.showContentColumn)?_c('div',{staticClass:"d-none col-lg-6 col-xl-7 d-print-none",class:`d-${_vm.headerMainHookBreakpointVisibility}-block`},[_c('HeaderMainHook')],1):_vm._e(),_c('div',{staticClass:"d-none d-md-flex justify-content-md-end d-print-none",class:[
          _vm.$contentSettings.functions.compare
            || _vm.$contentSettings.functions.wishList
            ? 'col-md-auto'
            : 'col-md-6 col-lg-auto',
            _vm.$themeSettings.components.HeaderMain.cartInfoAlignment
        ]},[_c('CartInfo',{attrs:{"id":"CartInfo"}}),(_vm.$themeSettings.components.CurrencySwitcher.enable && _vm.$themeSettings.components.HeaderMain.showCurrencySwitcher)?_c('CurrencySwitcher'):_vm._e(),(_vm.$store.state.cart.isLoaded && _vm.$store.state.cart.cartData && _vm.$store.state.cart.cartData.items && _vm.$store.state.cart.cartData.items.length > 0)?_c('Dropdown',{attrs:{"options":{ placement: 'bottom-end' },"trigger":"CartInfo"}},[_c('LazyComponent',{attrs:{"component-factory":_vm.LazyCartInfoDropDownContent,"wrapper-class":"LazyCartInfoDropDownContent"}})],1):_vm._e()],1)]),_c(_vm.mainMenuMobileComponent,{ref:"MainMenuMobile",tag:"component"})],1),(_vm.$themeSettings.components.HeaderMain.mobileSearchAutocomplete)?_c('HeaderSearch'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }