<script>
export default {
  data() {
    return {
      accessiblePhoneNumber: null,
      phoneHref: null,
    }
  },
  created() {
    this.accessiblePhoneNumber = this.formatAccessiblePhoneNumber(this.$store.state.globals.contact.phone)
    this.phoneHref = this.$store.state.globals.contact.phone.replace(/ /g, '')
  },
  methods: {
    formatAccessiblePhoneNumber(value) {
      let phone = value.replace(/ /g, '')
      let phoneNumbersArray = []
      for (let i = 0; i < phone.length; i++) {
        phoneNumbersArray.push(phone.charAt(i))
      }
      return phoneNumbersArray.join(' ')
    },
  },
}
</script>
