import HeaderMain from 'themes/base/components/shop/HeaderMain'

export default {
  extends: HeaderMain,
  data() {
    return {
      phoneIcon: require('@icon/phone-simple.svg?raw'),
    }
  },
  computed: {
    openingHours() {
      return `(${this.contact.openingHours})`
    },
    phoneText() {
      return `tel.: ${this.contact.phone}`
    },
    phonePrepend() {
      return this.$t('HeaderMain.contactInfo.phonePrepend')
    },
  },
}
